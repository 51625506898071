<template>
  <div id="map"
       :style="{width, height}">
    <yandex-map :controls="['geolocationControl']"
                :detailed-controls="{geolocationControl: { position: {top: 10, right: 10}}}"
                :options="{suppressMapOpenBlock:true}"
                @map-was-initialized="initHandler"
                :coords="location || coords"
                :zoom="zoom"
                :settings="settings"
                :style="{width:'100%', height:'100%'}">
      <on-map-place v-for="(place, index) in places"
                    :key="`place-${index}`"
                    :number="useNumber ? index : null"
                    :id="place.id"
                    entity="places"
                    :coords="place.location"
                    :image="getImage(place.images)"
                    :address="place.address"
                    :audio="place.audios && place.audios.length ? place.audios[0] : ''"
                    :icon="place.icon ? place.icon : null"
                    :setting_icon="place.type && place.type.icon_settings"
                    :title="place.title || place.name"/>
      <on-map-event v-for="(event, index) in events"
                    :key="`event-${index}`"
                    :number="useNumber ? index : null"
                    :id="event.id"
                    :coords="event.place ? event.place.location : event.location"
                    :image="getImage(event.images)"
                    :address="event.place ? event.place.address : event.address"
                    :icon="event.badge ? event.badge.image : null"
                    :setting_icon="event.badge && event.badge.icon_settings"
                    :title="event.title || event.name"/>
    </yandex-map>
  </div>
</template>

<script>
  import OnMapPlace from './OnMapPlace.vue';
  import OnMapEvent from './OnMapEvent.vue';

  export default {
    name: 'OnMap',
    components: {
      OnMapPlace,
      OnMapEvent
    },
    props: {
      params: {},
      location: null,
      places: {},
      events: {},
      useNumber: {
        default: false,
      },
    },
    data() {
      return {
        coords: [process.env.VUE_APP_DEFAULTH_LATITUDE, process.env.VUE_APP_DEFAULTH_LONGITUDE],
        zoom: process.env.VUE_APP_DEFAULTH_MAP_ZOOM,
        settings: {
          apiKey: '3ce74472-614d-48ae-be3b-43d1739a0368',
          lang: 'ru_RU',
          coordorder: 'latlong',
          version: '2.1',
        },
      };
    },
    computed: {
      width() {
        return this?.params?.sizeMap?.width ? this.params.sizeMap.width : '100%';
      },
      height() {
        return this?.params?.sizeMap?.height ? this.params.sizeMap.height : '100%';
      },
    },
    created() {
      this.coords = this?.params?.coords ? this.params.coords : this.coords;
      this.zoom = this?.params?.zoom ? this.params.zoom : this.zoom;
    },
    watch: {
      params() {
        this.coords = this?.params?.coords ? this.params.coords : this.coords;
      },
    },
    methods: {
      getImage(images) {
        return Array.isArray(images) && images[0] ? images[0] : '';
      },
      initHandler(obj) {
        const geo = ymaps.geolocation;
        geo.get({
          provider: 'browser',
        })
          .then((result) => {
            result.geoObjects.options.set('preset', 'islands#geolocationIcon');
            obj.geoObjects.add(result.geoObjects);
          });
      },
    },
  };
</script>

<style lang="scss">
  .ymap-container {
    * {
      box-shadow: none !important;
    }
  }

  [class$="-balloon__content"] {
    padding: 0 !important;
    background: transparent !important;
  }

  [class$="-balloon__layout"] {
    background: transparent !important;
  }

  [class$="-balloon__tail"] {
    border: none !important;
    background: transparent !important;
  }
</style>
