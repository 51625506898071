<template>
  <div class="balloon">
    <div class="balloon__wrapper">
      <a v-if="entity === 'places'"
         :href="link">
        <img class="balloon__image"
             v-if="Boolean(image)"
             :src="image"/>
      </a>
      <div v-else>
        <img class="balloon__image"
             v-if="Boolean(image)"
             :src="image"/>
      </div>
      <div class="balloon__info">
        <h3 class="balloon__title">{{title}}</h3>
        <span class="balloon__description">{{address}}</span>
        <div v-if="Boolean(audio)"
             id="popup-on-map-player-button"
             class="player__button"
             :class="{'player__button-playing': playing}"
             style="width: 32px; height: 32px">
          <svg viewBox="0 0 20 20"
               class="icon player__button-icon"
               style="width: 14px; height: 14px;">
            <use xlink:href="#play"></use>
          </svg>
          <audio ref="audiofile" preload="auto">
            <source :src="audio" type="audio/ogg"/>
            <source :src="audio" type="audio/mp3"/>
          </audio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopUp',
  props: {
    id: undefined,
    entity: {},
    image: {
      type: String,
      default: '',
    },
    audio: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    playing: {
      type: Boolean,
      default: false,
    },
    address: {
      type: String,
      default: '',
    },
    service: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      volume: 100,
      audioEl: undefined,
    };
  },
  computed: {
    link() {
      return `/${this.entity}/${this.id}`;
    },
    placeUrl: (vm) => `/${vm.service}/${vm.id}`,
  },
};
</script>

<style lang="scss">
  .balloon {
    width: 300px;
    background: white;
    border-radius: 5px;
    overflow: hidden;

    &__wrapper {

    }

    &__image {
      width: 100%;
      height: 145px;
    }

    &__info {
      padding: 20px;
    }

    &__title {
      margin: 0 0 10px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #000000;
    }

    &__description {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: rgba(0, 0, 0, .5);
    }
  }
</style>
