<template>
  <ymap-marker v-if="$badges.length"
               :marker-id="id"
               :coords="coords"
               :icon="markerSymbol()">
    <PopUp slot="balloon"
           :id="id"
           :title="title"
           :image="image"
           service="content/event"
           :address="address"></PopUp>
  </ymap-marker>
</template>

<script>
import PopUp from './PopUp.vue';

export default {
  name: 'OnMapPlace',
  components: {
    PopUp,
  },
  props: {
    coords: {
      type: Array,
      required: true,
    },
    address: String,
    image: {
      type: String,
    },
    title: {},
    name: {},
    id: {},
    number: {},
    icon: {
      type: String,
      default: '',
    },
    setting_icon: null,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    markerSymbol() {
      let size = [44, 54];
      if (this.setting_icon) {
        size = [parseInt(this.setting_icon.width, 10), parseInt(this.setting_icon.height, 10)];
      }
      const marker = {
        layout: this.number !== null ? 'default#imageWithContent' : 'default#image',
        imageHref: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDciIGhlaWdodD0iNDYiIHZpZXdCb3g9IjAgMCA0NyA0NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMy42NTUzIDQwQzM0LjEzNDMgNDAgNDIuNjI5MyAzMS40OTM0IDQyLjYyOTMgMjFDNDIuNjI5MyAxMC41MDY2IDM0LjEzNDMgMiAyMy42NTUzIDJDMTMuMTc2MiAyIDQuNjgxMjYgMTAuNTA2NiA0LjY4MTI2IDIxQzQuNjgxMjYgMzEuNDkzNCAxMy4xNzYyIDQwIDIzLjY1NTMgNDBaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSI4LjY3NTc4IiB5PSI2IiB3aWR0aD0iMjkuOTU5IiBoZWlnaHQ9IjMwIiByeD0iMTQuOTc5NSIgZmlsbD0iIzAwQTM2NyIvPgo8L3N2Zz4=',
        imageSize: size,
        imageOffset: size.map((value) => -value / 2),
      };
      if (this.icon) {
        marker.imageHref = this.icon;
      }
      if (this.icon && this.icon.indexOf('.svg') + 1) {
        const div = document.createElement('div');
        div.innerHTML = this.$badgesSVG.find((svg) => svg.url === this.icon).img;
        div.getElementsByClassName('color-marker-symbol')
          .forEach((el) => {
            const element = el;
            element.style.fill = this.setting_icon.color ? this.setting_icon.color : '#000000';
            element.style.opacity = this.setting_icon.transparency || 1;
          });
        const s = new XMLSerializer().serializeToString(div.getElementsByTagName('svg')[0]);
        marker.imageHref = `data:image/svg+xml;base64,${window.btoa(s)}`;
      }
      if (this.number !== null) {
        marker.contentLayout = `<span style="color: #FFFFFF; font-weight: bold;">${this.number + 1}</span>`;
        marker.imageSize = [40, 40];
        marker.contentOffset = [15, 9];
        marker.content = 'content';
      }
      return marker;
    },
  },
};
</script>
